:root {
   --headings-color: #333333;
   --lighter-text: #777777;
   --text-color: #333232;
   --background-color: white;
   --footer-text: rgb(241, 241, 240);
}

*,
*::before,
*::after {
   box-sizing: border-box;
}
html {
   font-size: 62.5%;
}

body {
   margin: 0;
   padding: 0;
   font-size: 1.6rem;
   font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
   font-family: "Open Sans", Arial, Helvetica, sans-serif;
   color: var(--text-color);
}

.container,
.small-container {
   padding: 0 2rem;
   margin: auto;
}
.container {
   max-width: 1200px;
}
.small-container {
   max-width: 1080px;
}

/* Typography */
h1,
h2,
h3,
h3,
h4 {
   font-family: "Open Sans", sans-serif;
   font-weight: 500;
   color: var(--headings-color);
   margin-bottom: 1rem;
}
h1 {
   font-size: 4rem;
}
h2 {
   font-size: 3rem;
}
h3 {
   font-size: 2.4rem;
}
h4 {
   font-size: 1.7rem;
}

@media screen and (min-width: 768px) {
   h1 {
      font-size: 4.6rem;
   }
   h2 {
      font-size: 3.6rem;
   }
   h3 {
      font-size: 2.8rem;
   }
   h4 {
      font-size: 1.8rem;
   }
}

/* Flexbox*/
.center {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100%;
   height: 100%;
}
.row {
   display: flex;
   flex-wrap: wrap;
}

.col-25 {
   width: 25%;
}
.col-50 {
   width: 50%;
}
.col-75 {
   width: 75%;
}
.col-100 {
   width: 100%;
}
.flex--col {
   display: flex;
   flex-direction: column;
}
@media screen and (max-width: 992px) {
   .col-75 {
      width: 100%;
   }
}
@media screen and (max-width: 768px) {
   .col-25 {
      width: 50%;
   }
   .col-50 {
      width: 100%;
   }
}

@media screen and (max-width: 576px) {
   .col-25 {
      width: 100%;
   }
}

/* Lists */
.list {
   list-style: none;
}

/* Colllapsibles */
.collapsible {
   font-size: 1.8rem;
}

.collapsible--expanded {
   background-color: white;
}
.collapsible__header {
   display: flex;
   justify-content: space-between;
}
.collapsible__content {
   max-height: 0;
   overflow: hidden;
   opacity: 0;
   transition: all 0.4s;
}

.collapsible--chevron {
   transform: rotate(-90deg);
   transition: transform 0.3s;
   fill: rgb(121, 121, 121);
   width: 2rem;
}
.collapsible--expanded .collapsible--chevron {
   transform: rotate(0);
}
.collapsible--expanded .collapsible__content {
   max-height: 100vh;
   opacity: 1;
   margin-bottom: 1rem;
}
@media screen and (min-width: 768px) {
   .collapsible--expanded .collapsible__content {
      margin-bottom: 0;
   }
}

/* Links */
a {
   text-decoration: none;
   color: var(--text-color);
}
a:hover {
   color: grey;
}
/* Icons */
.icon {
   color: var(--text-color);
}
.icon:hover {
   color: var(--lighter-text);
}
.icon--red {
   color: rgb(219, 3, 3);
}
/* Buttons */

.btn {
   border: 2px solid var(--headings-color);
   border-radius: 50px;
   padding: 1rem 3rem;
   font-size: 1.7rem;
   cursor: pointer;
}
.btn--block {
   width: 100%;
   margin: 0 auto;
}

.btn--outline {
   background: var(--background-color);
}
.btn--outline:hover {
   background: var(--headings-color);
   color: var(--background-color);
}

/* Inputs*/
input,
select,
textarea {
   border: 1px solid #ccc;
   padding: 0.5rem 1.5rem;
   font-size: 1.6rem;
   border-radius: 5px;
   background-color: white;
   color: var(--text-color);
   margin-top: 0.5rem;
}

select {
   margin-bottom: 1.5rem;
   font-family: inherit;
   width: 100%;
}

textarea:focus,
input:focus,
select:focus,
input:active,
select::selection {
   outline: 2px solid rgb(185, 229, 247);
}

.contact--form {
   width: 100%;
   display: flex;
   flex-direction: column;
}
.contact--form form {
   background-color: white;
   border: 1px solid #ccc;
   border-radius: 5px;
   box-shadow: 10px 10px 20px -10px #ccc;
   padding: 2rem;
   display: flex;
   flex-direction: column;
   width: 100%;
}

.contact--form form .btn {
   width: 60%;
   margin-top: 1rem;
   align-self: center;
}

.form-control {
   display: flex;
   width: 100%;
}

.form-group {
   margin-bottom: 2rem;
}

.error {
   margin-top: 0;
   background-color: rgb(247, 141, 141);
   padding: 1rem;
}

@media screen and (min-width: 768px) {
   .contact--form {
      flex-direction: row;
   }
   .contact--text {
      width: 40%;
      margin-right: 8rem;
      padding-top: 2.4rem;
      padding-left: 2rem;
   }
   .form--section {
      width: 60%;
   }
}

/*Tables*/
table {
   width: 100%;
}
td,
th {
   padding: 0.5rem 1rem;
   text-align: left;
}
td {
   vertical-align: top;
}

table img {
   width: 100%;
   object-fit: cover;
}

/* Animations */
.logo {
   animation-name: logoSpin;
   animation-duration: 3s;
   animation-delay: 1s;
   animation-fill-mode: forwards;
   position: absolute;
   height: 60px;
   visibility: hidden;
}
.logoImage {
   height: 60px;
}
.logo img {
   height: 100%;
}

@keyframes logoSpin {
   0% {
      transform: scale(0.2);
      opacity: 0.2;
      top: 50vh;
      left: 50%;
      visibility: visible;
   }

   50% {
      transform: scale(3);
      opacity: 1;
      top: 50vh;
      left: 50%;
   }

   100% {
      transform: scale(1) rotateY(360deg);
      top: 15px;
      left: 2rem;
   }
}

/* Grid */
.grid {
   display: grid;
   justify-items: center;
   grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
   column-gap: 1.5rem;
   row-gap: 1.5rem;
}

/* Block*/
.block {
   padding-top: 4rem;
   padding-bottom: 6rem;
}

/* Modal*/
.modal {
   background-color: rgb(0, 0, 0);
   background-color: rgba(0, 0, 0, 0.4);
   width: 100%;
   height: 100%;
   position: fixed;
   top: 0;
   left: 0;
   overflow: auto;
   z-index: 99;
   display: none;
}
.modal__header {
   margin-bottom: 3rem;
}
.modal__content {
   margin: 100px auto 0;
   width: 80%;
   border: 1px solid #888;
   background-color: #fefefe;
   padding: 2rem 2rem 6rem;
   border-radius: 5px;
   box-shadow: 0 0;
   overflow-y: auto;
}

.modal--link {
   cursor: pointer;
}

.show__modal {
   display: block;
}

.close__modal {
   float: right;
   padding: 0 1rem;
   font-size: 3rem;
   transition: box-shadow 0.2s;
}
.close__modal:hover {
   box-shadow: 0 0 0 2px #ccc;
   cursor: pointer;
   border-radius: 5px;
}

/* Navbar */
.nav {
   position: fixed;
   top: 0;
   left: 0;
   right: 0;
   display: flex;
   z-index: 99;
   transition: all 0.2s;
}
.nav--white {
   background: white;
   box-shadow: 0 0 10px 0 rgb(230, 228, 228);
}

.navbar {
   position: relative;
   display: flex;
   width: 100%;

   padding: 1rem 2rem;
   justify-content: space-between;
   align-items: center;
   flex-wrap: wrap;
}
.navbar__brand {
   display: flex;
   align-items: center;
}
.navbar__brand:hover {
   color: var(--text-color);
}
.navbar__brand-heading,
.brand--heading {
   margin: 0 1.5rem;
   font-weight: 500;
   display: none;
   font-size: 3rem;
}

.nav__list {
   margin: 0;
   padding: 0;
   width: 100%;
}

.nav__items {
   margin-right: 2.5rem;
}
.navbar__toggler {
   opacity: 0.7;
   transition: box-shadow 0.15s;
   cursor: pointer;
   font-size: 2.5rem;
}

.nav.collapsible--expanded .nav__list {
   margin-top: 1.5rem;
}

.nav.collapsible--expanded .nav__items {
   padding-top: 1rem;
}
.nav.collapsible--expanded .navbar__toggler {
   opacity: 1;
   box-shadow: 0 0 0 3px #ccc;
   border-radius: 5px;
}
.nav--icons {
   display: flex;
   flex: 1;
   justify-content: flex-end;
   align-items: center;
}

.nav__cart-badge {
   position: absolute;
   height: 2rem;
   width: 2rem;
   background-color: blue;
   color: white;
   border-radius: 50%;
   text-align: center;
   padding: 0.2rem 0;
   top: -10px;
   left: 10px;
   font-size: 1.2rem;
   font-weight: 700;
}
.nav__cart {
   align-self: center;
}
.nav__cart__icon {
   position: relative;
   padding-right: 2rem;
}

.adminNav {
   background-color: rgb(255, 255, 255);
   border-radius: 10px;
   box-shadow: 5px 5px 20px -5px #ccc;
   flex-direction: column;
   max-height: max-content;
   padding: 2rem 3rem 2rem 2rem;
   position: absolute;
   right: 20px;
   row-gap: 1rem;
   top: 50px;
   width: fit-content;
   display: none;
}

.adminNav.display {
   display: flex;
}

@media screen and (min-width: 568px) {
   .navbar__brand-heading {
      display: block;
   }
}

@media screen and (min-width: 980px) {
   .navbar__toggler {
      display: none;
   }
   .nav__list {
      width: auto;
      display: flex;
      font-size: 1.7rem;
      max-height: 100%;
      opacity: 1;
      margin-left: 1rem;
      order: 2;
      flex: 1;
      justify-content: flex-end;
   }
   .nav--icons {
      order: 3;
      flex: 0;
   }
   .nav.collapsible--expanded .nav__list {
      margin-top: 0;
   }

   .nav.collapsible--expanded .nav__items {
      padding-top: 0;
   }
}

/* Product cards */
.card {
   background-color: #fcfcfc;
   box-shadow: 0 0 20px 3px lightgray;
   width: fit-content;
   padding: 2rem;
   border-radius: 10px;
   text-align: center;
}
.product--card {
   /*box-shadow: 0 0 20px 3px lightgray;*/
   position: relative;
   overflow: hidden;
   transition: transform 0.5s;
   background-color: #fcfcfc;
}
.product--card:hover {
   transform: scale(1.05);
}
.product--card-header {
   min-height: 300px;
   max-height: 400px;
   background-size: cover;
   background-position: center;
}
.product--card-header img {
   width: 100%;
   object-fit: cover;
}

.product--card-body {
   padding: 0 1.5rem;
}
.product--card-body ul {
   text-align: left;
   font-size: 14px;
   font-style: italic;
}
.product--card-heading {
   margin-top: 2rem;
   margin-bottom: 0.5rem;
   font-weight: 700;
}

.product--card-text {
   color: var(--lighter-text);
}
.product--card-price {
   color: var(--headings-color);
   font-size: 1.7rem;
   font-weight: 500;
}
.product--card-footer {
   margin: 2rem 0;

   display: flex;
   justify-content: center;
   align-items: center;
}
.product--card-label {
   position: absolute;
   top: 30px;
   right: 0;
   border-top-left-radius: 50px;
   border-bottom-left-radius: 50px;
   background: black;
   width: 8rem;
   height: 4rem;
   text-align: center;
   color: white;
   padding-top: 1rem;
   box-shadow: 0 0 10px 0 darkgray;
   letter-spacing: 1.8px;
}
.products--wrapper {
   display: flex;
   flex-direction: column;
}

/* Sections */
.main {
   padding-top: 8rem;
   padding-bottom: 3rem;
   min-height: 100vh;
}
.main__background {
   background-image: url(../images/Optimized-Website-Wallpaper-White.jpg);
   background-size: 400px;
   background-position-y: 80px;
   background-position-x: -60px;
}

/* Hero section */
.hero {
   position: relative;
   height: 100vh;
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
   padding-top: 80px;
}

.hero::before {
   content: "";
   background-image: url("../images/background_6837.jpeg");
   background-size: cover;
   background-position: center;
   position: absolute;
   top: 0px;
   right: 0px;
   bottom: 0px;
   left: 0px;
   opacity: 0.6;
}
.hero__title {
   position: relative;
   text-align: center;
   opacity: 1;
   margin: 0;
}
.hero__title-heading {
   font-family: "Dancing Script", cursive;
   font-size: 3.5rem;
   color: var(--text-color);
   font-weight: 500;
   margin: 0;
   padding: 0;
   margin-block: 0;
}

.hero__title .brand--heading {
   display: block;
   font-size: 4rem;
   font-weight: 500;
   color: black;
   margin-bottom: 1.5rem;
}
.hero__icon {
   color: red;
   font-size: 2.5rem;
   margin-left: 1rem;
   opacity: 0.8;
}

.hero__products-link {
   margin-top: 5rem;
}
.hero__products-link button {
   background-color: transparent;
   padding-right: 8rem;
   padding-left: 8rem;
}

@media screen and (min-width: 568px) {
   .hero__title {
      max-width: 568px;
   }
   .hero__title-heading {
      font-size: 7rem;
   }
   .hero__title .brand--heading {
      display: none;
   }
   .hero__icon {
      font-size: 4rem;
   }
}

/* Offer section*/
.offer {
   text-align: center;
}
.offer__heading {
   justify-content: center;
   display: flex;
   align-items: center;
   margin-top: 4rem;
   margin-bottom: 6rem;
}
.offer__heading h3 {
   margin: 0;
}

.offer .grid {
   row-gap: 30px;
   margin-top: 3rem;
}

.header__line {
   height: 2px;
   border-radius: 50%;
   width: 10%;
   margin: 0 1.5rem;
   text-align: center;
   background-color: var(--lighter-text);
}

@media screen and (min-width: 768px) {
   .offer__grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      gap: 20px;
   }
}
/* Product details */
.product--card {
   max-width: 300px;
}

.product--details {
   box-shadow: 0 0 5px 0 lightgray;
   padding: 2rem;
   background-color: #fcfcfc;
   max-width: 100%;
}

.product--details.container {
   max-width: 1080px;
   padding: 0;
}
.product--card-header {
   background: transparent url("../images/loading.gif") no-repeat scroll center
      center;
   height: 200px;
}
.product--card-header .product__image {
   width: 280px;
   max-height: 280px;
   object-fit: cover;
}

.product--image {
   max-width: 350px;
   min-width: 250px;
}
.product--image img {
   width: 100%;
   object-fit: cover;
}

.product__details-wrapper {
   display: flex;
   flex-direction: column;
   width: 100%;
}
.product__details-order-wrapper {
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
   margin: 1rem 0;
}
.product__details-wrapper ul {
   margin: 0 0 2rem;
}
.product__details-info {
   font-size: 14px;
   font-style: italic;
}
.product__details-info p:first-child {
   margin-top: 0;
}
.product__details-colors {
   display: flex;
   margin-bottom: 1rem;
   justify-content: center;
}

.product__details-description,
.product__details {
   padding: 0;
}
.product__details-description {
   max-width: 100%;
}
.product__details-description h2 {
   margin-top: 1rem;
}

.product__details {
   width: 100%;

   display: flex;
   flex-direction: column;
}

.product__details .btn {
   margin-top: 2rem;
}
.product__details p {
   color: black;
   font-size: 1.4rem;
   font-weight: 600;
   margin: 0;
}
.product__details-order-wrapper section:nth-child(2) {
   margin-top: 4rem;
}

.hidden {
   display: none;
}

.product--details-horisontal {
   border: 1px solid #ccc;
   border-radius: 50px;
   margin: 2rem 0;
}
.product--buy-form {
   margin-top: 2rem;
   width: 100%;
}

@media screen and (min-width: 990px) {
   .product--details.grid--1x2 {
      grid-template-columns: 50% 50%;
      max-width: 80%;
   }
   .product__details-description h2 {
      margin-top: 0;
   }
   .product__details {
      max-width: 50%;
      min-width: 280px;
      justify-content: space-between;
   }
   .product__details-order-wrapper {
      justify-content: start;
   }
   .product__details-wrapper {
      padding: 0 5rem 0 3rem;
   }
   .product__details-colors {
      justify-content: flex-start;
   }
}

/* Cart page */
.cart--wrapper {
   width: 100%;
   margin-top: 1.5rem;
   display: flex;
   flex-direction: column;
}
.cart--products-wrapper {
   display: flex;
   flex-direction: column;
   width: 100%;
}
.cart--product-wrapper,
.cart--total-wrapper {
   padding: 1.5rem;
   border: 1px solid lightgrey;
   border-radius: 5px;
   display: flex;
   background-color: white;
}
.cart--total-wrapper {
   flex-direction: column;
   margin-top: 1.5rem;
   width: 100%;
   height: fit-content;
}
.cart--product-wrapper {
   margin-bottom: 1.5rem;
}
.cart--product__details-wrapper {
   display: flex;
   flex-wrap: wrap;
   flex-direction: column;
   flex: 1;
   margin-left: 1.5rem;
   justify-content: space-evenly;
}

.cart--product__details {
   display: flex;
   flex-direction: column;
}
.cart--product-header,
.cart--total-header {
   font-weight: 700;
}
.cart--product-header {
   font-size: 1.6rem;
   margin: 0 0 0.5rem;
}
.cart--total-header {
   font-size: 1.8rem;
}
.cart--product__image {
   width: 75px;
}
.cart--product__image img {
   width: 100%;
   object-fit: cover;
}
.cart--product__details p,
.cart--product__details span {
   font-size: 1.3rem;
   color: rgb(102, 102, 102);
}
.cart--product__details p {
   display: none;
}
.cart--product__amount {
   display: flex;
   align-items: center;
   order: 3;
}
.cart--product__price {
   order: 2;
   font-size: 1.4rem;
   font-weight: 700;
   margin: 0.5rem 0;
}
.cart--product-delete {
   display: flex;
   justify-content: center;
   align-items: center;
   padding-right: 2rem;
}

.cart--sum-wrapper {
   display: flex;
   justify-content: space-between;
   margin-bottom: 2rem;
   font-weight: 600;
}

.cart--shippingText {
   font-size: 1.3rem;
   margin-bottom: 2rem;
}

.cart--total-header {
   margin-bottom: 2rem;
}
.cart--total-wrapper hr {
   width: 90%;
   border: 1px solid lightgrey;
   border-radius: 50%;
   opacity: 0.5;
   margin-bottom: 2rem;
}
.cart--item-amount {
   display: flex;
   align-items: center;
}
.cart--item_decrement,
.cart--item_increment {
   font-size: 2.5rem;
   color: #ccc;
   transition: transform 0.2s;
}
.cart--item_increment:hover,
.cart--item_decrement:hover {
   transform: scale(1.2);
}
.cart--item__number {
   margin: 0 1.5rem;
}

@media screen and (min-width: 900px) {
   .cart--wrapper {
      flex-direction: row;
   }
   .cart--products-wrapper {
      width: 70%;
   }
   .cart--total-wrapper {
      margin-top: 0;
      margin-left: 1.5rem;
      width: 30%;
   }

   .cart--product__details-wrapper {
      flex-direction: row;
      justify-content: flex-start;

      margin-right: 4rem;
   }
   .cart--product__details {
      flex: 1;
   }
   .cart--product__details p {
      display: block;
      margin-top: 0.5rem;
   }
   .cart--product__amount {
      align-self: center;
      margin-right: 3rem;
      margin-left: 2rem;
      order: 2;
   }
   .cart--product__price {
      align-self: center;
   }
}

/* Footer */
.footer {
   background-color: #000;
}

.footer-wrapper {
   padding: 1rem 2rem;
   color: var(--footer-text);
   display: flex;
   flex-direction: column;
}
.footer__logo {
   max-width: 300px;
   align-self: center;
   padding: 1rem;
   order: 3;
}
.footer .collapsible {
   font-size: 1.6rem;
   min-width: 200px;
}
.footer__social {
   font-size: 2.2rem;
}
.social--wrapper {
   margin-top: 1rem;
   display: flex;
   flex-direction: row;
}
.social--wrapper p {
   margin-bottom: 1rem;
   margin-right: 2rem;
}
.social--wrapper i:hover {
   color: var(--lighter-text);
}
.footer .list {
   margin-top: 0rem;
   padding-left: 1rem;
}

.footer a {
   color: var(--footer-text);
}

.footer p {
   color: var(--footer-text);
   margin-top: 0;
}
.footer li {
   margin-bottom: 0.5rem;
}
.footer h4 {
   color: white;
   margin: 0 0 0 0;
   font-size: 2rem;
   cursor: pointer;
}
.footer__icon {
   margin-right: 1rem;
   padding-left: 1rem;
}
.footer a:hover {
   color: var(--lighter-text);
}

.footer .collapsible__header {
   display: flex;
   justify-content: space-between;
   padding-bottom: 1rem;
   cursor: pointer;
}
.footer .collapsible--expanded {
   background-color: #000;
}

@media screen and (min-width: 768px) {
   .footer-wrapper {
      flex-direction: row;
      justify-content: space-evenly;
   }

   .footer .collapsible--chevron {
      display: none;
   }
   .footer .collapsible__content {
      opacity: 1;
      max-height: 100%;
   }

   .footer__logo {
      order: 0;
   }
}

/*Klarna checkout*/
.iframe {
   height: 650px;
   width: 80vw;
}
.checkout--wrapper {
   display: flex;
   justify-content: center;
}

/* Fabrics modal */
.fabrics--grid {
   display: grid;
   gap: 20px;
   grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
   justify-items: center;
   margin-top: 2rem auto;
   width: 100%;
}
.fabric__details {
   align-items: center;
   border-radius: 5px;
   border: 1px solid #ccc;
   box-shadow: 5px 5px 20px 0px #ccc;
   display: flex;
   flex-direction: column;
   justify-content: center;
   overflow: hidden;
   padding: 2rem;
   transition: transform 0.2s;
   width: fit-content;
}
.fabric_images {
   display: flex;
}
.fabric__image {
   border: 1px solid lightgrey;
   width: 100px;
   height: 100px;
}
.fabric__image img {
   width: 100%;
   object-fit: cover;
}

.fabric__details:hover {
   transform: scale(1.2);
}
.image__subtext {
   margin-top: 0.5rem;
   font-weight: 700;
   text-transform: capitalize;
}
@media screen and (min-width: 768px) {
   .fabrics--grid {
      grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
   }
   .fabric__image {
      width: 150px;
      height: 150px;
   }
}

/*About section*/
.about--section {
   display: flex;
   flex-direction: column;
   width: 100%;
}
.about__image-wrapper {
   height: 50vh;
   margin-bottom: 6rem;
   width: 100%;
}

.about__image-wrapper img {
   border-radius: 10px;
   height: 100%;
   object-fit: cover;
   width: 100%;
}

.about__text {
   width: 100%;
   text-align: center;
}
.about__text h3 {
   margin-top: 0;
}
.about__text p {
   line-height: 1.3;
   letter-spacing: 1px;
}

@media screen and (min-width: 769px) {
   .about--section {
      flex-direction: row;
   }
   .about__image-wrapper {
      height: 60vh;
      width: 40%;
      min-width: 400px;
      margin-bottom: 0;
      margin-right: 8rem;
   }
   .about__text {
      text-align: left;
      padding-right: 8rem;
   }
   .about__text h3 {
      margin-top: 0;
   }
}
@media screen and (min-width: 900) {
}

/*Admin*/
.productsTable {
   padding-top: 2rem;
}
.productsTable table td {
   vertical-align: top;
}

.deleteModal {
   align-items: center;
   background-color: rgba(0, 0, 0, 0.6);
   display: flex;
   height: 100vh;
   justify-content: center;
   left: 0;
   position: fixed;
   top: 0;
   width: 100%;
   z-index: 9000;
}
.deleteModal__box {
   align-items: center;
   background-color: white;
   border-radius: 15px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   overflow: hidden;
}
.deleteModal__header {
   padding: 1rem 3rem;
   margin-bottom: 3rem;
}
.deleteModal__footer {
   margin-bottom: 3rem;
   padding: 0 2rem;

   width: 100%;
   display: flex;
   justify-content: space-around;
}
.btn--cancel {
   background-color: rgb(211, 7, 7, 0.8);
}
.btn--confirm {
   background-color: rgba(2, 107, 2, 0.8);
   color: white;
}
.btn--confirm:hover {
   background-color: rgba(2, 107, 2, 1);
}
.btn--cancel:hover {
   background-color: rgb(211, 7, 7, 1);
   color: black;
}

/* Pagination */

.pagination {
   display: flex;
   list-style: none;
   justify-content: center;
}
.page-item {
   border-radius: 5px;
}
.page-item a {
   display: inline-block;
   cursor: pointer;
   padding: 0.5rem 1rem;
}
.page-item:hover {
   background-color: rgb(230, 230, 230);
}

.page-item.active {
   background-color: lightsalmon;
}
