.galleryWrap {
   display: grid;
   grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
   grid-auto-rows: 250px;
   gap: 10px;
   max-width: 1200px;
   margin: 2rem auto;
}
.galleryWrap .single {
   width: 100%;
   cursor: pointer;
}
.galleryWrap .single img {
   width: 100%;
   height: 100%;
   object-fit: cover;
   transition: all 0.2s ease-in-out;
}

.galleryWrap .single img:hover {
   transform: scale(1.02);
}

.modalWrap {
   position: fixed;
   top: 0;
   left: 0;
   overflow: auto;
   height: 100%;
   width: 100%;
   background-color: rgba(0, 0, 0, 0.4);
   z-index: 99;
   display: none;
   opacity: 0;
   animation: fadeIn 0.3s ease-in-out;
}
.appModal {
   margin: 100px auto;
   padding: 1rem;
   border: 1px solid grey;
   max-width: 600px;

   background-color: #fefefe;
   border-radius: 7px;
}

.modalWrap .appModal .imageWrap {
   height: 100%;
   width: 100%;
}

.imageWrap img {
   padding-top: 1rem;
   width: 100%;
   height: 100%;
   object-fit: cover;
}

.modalWrap.active {
   display: block;
   opacity: 1;
}

.modalWrap .close {
   float: right;
   font-size: 28px;
   font-weight: bold;
   cursor: pointer;
}

@keyframes fadeIn {
   from {
      opacity: 0;
   }
   to {
      opacity: 1;
   }
}

@media screen and (min-width: 768px) {
   .appModal {
      padding: 2.5rem;
   }
}
